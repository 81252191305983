html,
body {
  font-family: Arial, Verdana;
  background-color: #eef1ef;
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

h1 {
  margin-top: 2em;
  font-size: 2em;
  border-bottom: 1px solid #ccc;
}

h2 {
  margin-top: 1.5em;
  font-size: 1.8em;
  border-bottom: 1px solid #ccc;
}
h3 {
  font-size: 1.5em;
}

.wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  max-width: 1000px;
  margin: auto;
}

.map {
  height: 100vh;
  width: 100vw;
}

.save-button {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, 0);
}
